import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { ReportView } from './report-view'
import { Dashboard } from '../dashboard/dashboard'

export function ReportsPage() {
  const match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/:id/:bookmark?`}>
        <ReportView />
      </Route>
      <Route>
        <Dashboard />
      </Route>
    </Switch>
  )
}
