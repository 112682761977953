import { Theme, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { FormField } from '../../admin/shared'
import { createStyles, makeStyles } from '@mui/styles'
import { useGetWorkspaces } from '../../../hooks'
import { SkeletonFormField } from '../../admin/tabs'
import CustomDialog from '../../admin/shared/dialog/dialog'
import { WorkspaceInfo } from '../../../models'

const illegalNameChars = [
  '/',
  '\\',
  '?',
  '%',
  '*',
  ':',
  '|',
  '"',
  "'",
  '<',
  '>',
  '-',
  '=',
  '.',
]

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        padding: 0,
        margin: '16px 0',
      },
      dialogContent: {
        display: 'block',
        padding: theme.spacing(4),
      },
      error: {
        width: '100%',
        color: 'red',
      },
      form: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
    }),
  { name: 'copy-dialog' }
)

export function ReportCopyDialog(props: ReportCopyDialogProps) {
  const { isOpen, onClose, setCopyReport } = props

  const classes = useStyles()
  const [tempCopyName, setTempCopyName] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [workspace, setWorkspace] = useState<WorkspaceInfo | null>(null)

  const [error, setError] = useState<string | null>(null)

  const { data: workspaces, isLoading: loadingWorkspaces } = useGetWorkspaces()

  useEffect(() => {
    if (tempCopyName === '') {
      setError('Please enter a valid report name')
      return
    }
    if (tempCopyName === 'New Report') {
      setError('Report name cannot be "New Report"')
      return
    }
    if (illegalNameChars.some(char => tempCopyName.includes(char))) {
      setError(
        `Report name cannot include any of the following characters: ${illegalNameChars.join(
          ' '
        )}`
      )
      return
    }
    setError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempCopyName])

  const handleClose = () => {
    onClose()
    setTempCopyName('')
  }

  function WorkspaceFormField() {
    if (loadingWorkspaces) {
      return <SkeletonFormField />
    }

    //If there is only one option, auto select it
    if (workspaces?.length === 1 && !workspace) {
      setWorkspace({
        id: workspaces[0].id,
        name: workspaces[0].name,
        isReadOnly: workspaces[0].isReadOnly,
        profileId: workspaces[0].profileId,
        pbiWorkspaceId: workspaces[0].pbiWorkspaceId,
      })
    }

    return (
      <FormField
        label='Workspace'
        value={workspace?.name || ''}
        helperText=''
        selectOptions={workspaces?.map(ws => ws.name) || []}
        onTextChange={value => {
          const selectedWorkspace = workspaces?.find(ws => ws.name === value)
          if (!selectedWorkspace) return
          setWorkspace({
            id: selectedWorkspace.id,
            name: selectedWorkspace.name,
            isReadOnly: selectedWorkspace.isReadOnly,
            profileId: selectedWorkspace.profileId,
            pbiWorkspaceId: selectedWorkspace.pbiWorkspaceId,
          })
        }}
      />
    )
  }

  return (
    <CustomDialog
      open={isOpen}
      onClose={handleClose}
      title={'Save a Copy'}
      primaryButtonProps={{
        children: 'Confirm',
        disabled: !workspace || !!error,
        onClick: async () => {
          setCopyReport({
            name: tempCopyName,
            description: tempDescription,
            shareWithLicense: false,
            workspace: workspace,
          })
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        <FormField
          label='Title of Copy'
          value={tempCopyName}
          onTextChange={setTempCopyName}
        />
        {error && <Typography className={classes.error}>{error}</Typography>}{' '}
        <FormField
          label='Description'
          value={tempDescription}
          onTextChange={setTempDescription}
        />
        <WorkspaceFormField />
        {/* <Stack direction='row'>
          <Typography variant='body2' color='textSecondary'>
            Share to license
          </Typography>
          <Switch />
        </Stack> */}
      </form>
    </CustomDialog>
  )
}

type ReportCopyDialogProps = {
  isOpen: boolean
  onClose: () => void
  setCopyReport: (report: {
    name: string
    description: string
    shareWithLicense: boolean
    workspace: WorkspaceInfo
  }) => void
}
