import queryString from 'query-string'

const baseUrl = '/api' // 'http://127.0.0.1:7071/api'

export const endpoints = {
  reports: `${baseUrl}/reports`,
  licenseReports: `${baseUrl}/license-reports`,
  userReports: `${baseUrl}/user-reports`,
  appRegistration: `${baseUrl}/app-registration`,
  embedAccess: `${baseUrl}/embed-access`,
  users: `${baseUrl}/users`,
  testConnection: `${baseUrl}/test-report-connection`,
  userInfo: `${baseUrl}/user-info`,
  license: `${baseUrl}/license`,
  theme: `${baseUrl}/theme`,
  reportViewers: `${baseUrl}/report-viewers`,
  reportDefault: `${baseUrl}/report-default`,
  appRegistrationReports: `${baseUrl}/app-registration-reports`,
  paypalSubscriptionPlan: `${baseUrl}/paypal-subscription-plan`,
  paypalLicense: `${baseUrl}/paypal-license`,
  groups: `${baseUrl}/groups`,
  groupUsers: `${baseUrl}/group-users`,
  userGroups: `${baseUrl}/user-groups`,
  appSettings: `${baseUrl}/app-settings`,
  exportUsers: `${baseUrl}/export-users`,
  profiles: `${baseUrl}/profiles`,
  profileReports: `${baseUrl}/profile-reports`,
  workspaceProfiles: `${baseUrl}/profile-workspaces`,
  workspaceReports: `${baseUrl}/workspace-reports`,
  workspaces: `${baseUrl}/workspaces`,
  reportPages: `${baseUrl}/report-pages`,
  bookmark: `${baseUrl}/bookmark`,
  bookmarks: `${baseUrl}/bookmarks`,
  bookmarkViewers: `${baseUrl}/bookmark-viewers`,
  bookmarkMembers: `${baseUrl}/bookmark-members`,
  queries: `${baseUrl}/queries`,
  tables: `${baseUrl}/tables`,
  customReportItems: `${baseUrl}/custom-report-items`,
  exportCustomReport: `${baseUrl}/export-custom-report`,
  importCustomReport: `${baseUrl}/import-custom-report`,
  workspaceDatasets: `${baseUrl}/workspace-datasets`,
  workspaceUsers: `${baseUrl}/workspace-users`,
  capacities: `${baseUrl}/capacities`,
  perspectives: `${baseUrl}/perspectives`,
  folders: `${baseUrl}/folders`,
  folderItems: `${baseUrl}/folder-items`,
  reportGroups: `${baseUrl}/report-groups`,
  reportGroupMembers: `${baseUrl}/report-group-members`,
  dashboard: `${baseUrl}/dashboard`,
  favorites: `${baseUrl}/favorites`,
  navMenuItems: `${baseUrl}/nav-menu-items`,
}

export function getUrl(
  endpoint: string,
  query: { [key: string]: string | string[] }
) {
  return endpoint + '?' + queryString.stringify(query, { arrayFormat: 'comma' })
}
