import { useQuery } from 'react-query'
import { reportGroupApi } from '../../api-interface'

export const getReportGroupMembersKey = 'get-report-group-members'

export function useGetReportGroupMembers(reportGroupId: string) {
  return useQuery(
    [getReportGroupMembersKey, reportGroupId],
    () => reportGroupApi.getReportGroupMembers(reportGroupId),
    {
      enabled: !!reportGroupId,
    }
  )
}
