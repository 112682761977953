import cs from 'classnames'
import {
  Bookmark as BookmarkIcon,
  BookmarkBorder,
  SettingsOutlined,
  EditOutlined,
  EditOff,
  Settings,
  SaveAsOutlined,
} from '@mui/icons-material'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import {
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Toolbar,
  Typography,
  Icon,
} from '@mui/material'
import { useState } from 'react'
import { bookmarkAtom } from '../../../state'
import { useRecoilState } from 'recoil'
import { createStyles, makeStyles } from '@mui/styles'
import ReportSettingsDialog from '../../../components/layout/report-settings-dialog'
import { useGetDashboard, useUpdateReportSettings } from '../../../hooks'
import {
  AllReports,
  REPORT_TYPES,
  ReportSettings,
  WorkspaceInfo,
} from '../../../models'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ReportCopyDialog } from './copy-report-dialog'
import { ReportInfoDialog } from './report-info-dialog'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      toolbarShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
        },
        [theme.breakpoints.down('md')]: {
          height: '40px !important',
          paddingLeft: `${theme.spacing(2)} !important`,
          paddingRight: `${theme.spacing(2)} !important`,
        },
      },

      shrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
        },
      },
      appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        zIndex: 1301,
        borderBottom: '1px solid #e0e0e0',
      },
      toolbar: {
        minHeight: '40px',
        width: '100%',
        height: '64px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      reportBar: {
        minHeight: '32px',
        width: '100%',
        height: '48px',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      buttonShrink: {
        [theme.breakpoints.up('md')]: {
          height: '64px',
          width: '64px',
        },
        [theme.breakpoints.down('md')]: {
          height: '40px',
          width: '40px',
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      bookmarkButton: {
        marginLeft: theme.spacing(2),
        '&:hover, &.Mui-focusVisible': { color: theme.palette.secondary.dark },
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      rightSide: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      },
      logo: {
        marginLeft: theme.spacing(0.5),
        display: 'flex',
        alignItems: 'center',
      },
      titleShrink: {
        [theme.breakpoints.up('md')]: {
          fontSize: '1.25rem',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '1rem',
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'report-bar' }
)

export function ReportOptionsBar(props: ReportOptionsBarProps) {
  const {
    reportId,
    reportType,
    isOwner,
    isAdmin,
    editMode,
    toggleEditMode,
    showCopyDialog,
    setShowCopyDialog,
    hasRendered,
    setCopyReport,
  } = props
  const classes = useStyles()
  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)
  const [showSettingsDialog, setShowSettingsDialog] = useState(false)

  const { data: dashboard } = useGetDashboard()
  const reports: AllReports = dashboard?.reports
  const ownedReports = reports?.ownedReports || []
  const sharedReports = reports?.sharedReports || []
  const defaultReports = reports?.defaultReports || []
  const groupedReports = reports?.groupedReports || []
  const allReports = [
    ...ownedReports,
    ...sharedReports,
    ...defaultReports,
    ...groupedReports,
  ]
  const selectedReport =
    allReports?.find(report => report.id === reportId) || null

  const isPaginatedReport =
    reportType === REPORT_TYPES.CUSTOM_PAGINATED ||
    reportType === REPORT_TYPES.PAGINATED_REPORT

  const [openReportInfoDialog, setOpenReportInfoDialog] = useState(false)

  const {
    mutateAsync: updateReportSettings,
    isLoading: updatingReportSettings,
  } = useUpdateReportSettings()

  const handleBookmarksClick = () => {
    setBookmarkState({
      ...bookmarkState,
      panelOpen: !bookmarkState.panelOpen,
    })
  }

  const BookmarkButton = (
    <Tooltip title='Bookmarks'>
      <IconButton
        className={cs(classes.menuButton)}
        onClick={handleBookmarksClick}
        color='inherit'
        aria-label='bookmark'
        disabled={!hasRendered}
        size='large'
      >
        {!bookmarkState.panelOpen ? (
          <BookmarkBorder />
        ) : (
          <BookmarkIcon
            sx={{
              color: theme => theme.palette.primary.main,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  )

  const ResetButton = (
    <Tooltip title='Reset Report Filters'>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        disabled={!hasRendered}
        onClick={() => {
          setBookmarkState({
            ...bookmarkState,
            selectedBookmark: null,
            resetReportFilters: true,
          })
        }}
      >
        <RotateLeftIcon />
      </IconButton>
    </Tooltip>
  )

  const SettingsButton = (
    <Tooltip title='Report Settings'>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        onClick={() => setShowSettingsDialog(true)}
      >
        {showSettingsDialog ? <Settings /> : <SettingsOutlined />}
      </IconButton>
    </Tooltip>
  )

  const SettingsDialog = (
    <ReportSettingsDialog
      open={showSettingsDialog}
      onClose={() => setShowSettingsDialog(false)}
      filterPaneEnabled={selectedReport?.filterPaneEnabled}
      showPageNavigation={selectedReport?.showPageNavigation}
      saving={updatingReportSettings}
      setReportSettings={async (reportSettings: ReportSettings) => {
        await updateReportSettings({
          id: selectedReport.id,
          name: selectedReport.name,
          filterPaneEnabled: reportSettings?.filterPaneEnabled || false,
          showPageNavigation: reportSettings?.showPageNavigation || false,
        })
      }}
    />
  )

  const EditModeButton = (
    <Tooltip title={editMode ? 'Exit Edit Mode' : 'Enter Edit Mode'}>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        onClick={toggleEditMode}
        disabled={!hasRendered && reportType !== REPORT_TYPES.CUSTOM_PAGINATED}
      >
        {editMode ? (
          <EditOff
            sx={{
              color: theme => theme.palette.primary.main,
            }}
          />
        ) : (
          <EditOutlined />
        )}
      </IconButton>
    </Tooltip>
  )

  const SaveAsButton = (
    <Tooltip title={'Save a Copy'}>
      <IconButton
        color='inherit'
        aria-label='reset'
        size='large'
        onClick={() => setShowCopyDialog(true)}
        disabled={!hasRendered}
      >
        <SaveAsOutlined />
      </IconButton>
    </Tooltip>
  )

  return (
    <div>
      <Toolbar className={classes.reportBar}>
        <Stack direction='row' className={classes.leftSide}>
          <Typography
            variant='h6'
            className={classes.titleShrink}
            sx={{
              fontSize: '1rem',
              fontWeight: 600,
              color: theme => theme.palette.primary.main,
            }}
          >
            {selectedReport?.name}
          </Typography>
          {selectedReport && (
            <Tooltip title='Report Info'>
              <Icon
                color='inherit'
                aria-label='reset'
                sx={{
                  color: theme => theme.palette.primary.main,
                  '&:hover': {
                    color: theme => theme.palette.primary.dark,
                    backgroundColor: 'transparent',
                  },
                  cursor: 'pointer',
                }}
                onClick={() => setOpenReportInfoDialog(true)}
              >
                <InfoOutlinedIcon sx={{ fontSize: '16px', mb: 2 }} />
              </Icon>
            </Tooltip>
          )}
        </Stack>
        {selectedReport && (
          <ReportInfoDialog
            report={selectedReport}
            open={openReportInfoDialog}
            onClose={() => setOpenReportInfoDialog(false)}
          />
        )}
        <Stack direction='row' gap={0} className={classes.rightSide}>
          {!isOwner &&
            isAdmin &&
            selectedReport?.type === REPORT_TYPES.REPORT &&
            SaveAsButton}
          {isOwner &&
            selectedReport?.type !== REPORT_TYPES.PAGINATED_REPORT &&
            EditModeButton}
          {isOwner && !isPaginatedReport && SettingsButton}
          {!isPaginatedReport && ResetButton}
          {!isPaginatedReport && BookmarkButton}
        </Stack>
      </Toolbar>
      {SettingsDialog}
      <ReportCopyDialog
        setCopyReport={setCopyReport}
        onClose={() => setShowCopyDialog(false)}
        isOpen={showCopyDialog}
      />
    </div>
  )
}

type ReportOptionsBarProps = {
  reportId: string
  isOwner: boolean
  isAdmin: boolean
  editMode: boolean
  toggleEditMode
  showCopyDialog
  hasRendered: boolean
  setShowCopyDialog
  setCopyReport: (report: {
    name: string
    description: string
    shareWithLicense: boolean
    workspace: WorkspaceInfo
  }) => void
  reportType: string
}
