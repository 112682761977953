import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Icon,
  Stack,
} from '@mui/material'
import { useState } from 'react'

import { SearchAndPage } from '../../shared/existing-items/search-and-page'
import { NoResultsText } from '../../../../components'
import { LinkedItemChip, LinkedItemChips } from '../../shared'
import { Folder, FolderItem, ReportGroup } from '../../../../models'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import { LinkReportsMenu } from './link-reports-menu'
import {
  useGetReportGroupMembers,
  useRemoveReportGroupMember,
} from '../../../../hooks'
import { useDeleteFolder } from '../../../../hooks/mutations/useDeleteFolder'
import { EditableText } from '../../../reports/bookmarks/editable-text'

const PAGE_SIZE = 10

export function LinkedFolder(props: LinkedFolderProps) {
  const { folder, reportGroup, reportGroupFolders } = props
  const [itemSearchQuery, setItemSearchQuery] = useState('')
  const [itemPage, setItemPage] = useState(1)
  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: deleteFolder, isLoading: deleteLoading } =
    useDeleteFolder()
  const { mutateAsync: removeReportGroupMember } = useRemoveReportGroupMember()
  const { data: linkedMembers } = useGetReportGroupMembers(reportGroup.id)

  async function handleDelete(itemToDelete: FolderItem) {
    const newItems = folder.items?.filter(item => itemToDelete.id !== item.id)
    await updateFolder({
      ...folder,
      items: newItems,
      reportGroupId: reportGroup.id,
      folderId: folder.id,
    })

    //if the report is a key report, or it is in a different folder, do not remove it from the group

    const isKeyReport = !!linkedMembers?.find(
      member => member.id === itemToDelete.id
    )?.isKeyReport

    const otherFolders = reportGroupFolders.filter(f => f.id !== folder.id)

    const reportExistsInOtherFolder = otherFolders.some(f =>
      f.items?.find(i => i.id === itemToDelete.id)
    )

    if (isKeyReport || reportExistsInOtherFolder) {
      return
    }

    await removeReportGroupMember({
      reportGroupId: reportGroup.id,
      reportId: itemToDelete.id,
    })
  }

  const filteredItems =
    folder?.items?.filter(item =>
      item?.id.includes(itemSearchQuery.toLowerCase())
    ) || []

  const itemsTotalPages = Math.ceil(filteredItems?.length / PAGE_SIZE)

  const paginatedItems = filteredItems.slice(
    (itemPage - 1) * PAGE_SIZE,
    itemPage * PAGE_SIZE
  )

  function handleItemNextPage() {
    setItemPage(prevPage => prevPage + 1)
  }

  function handleItemPrevPage() {
    setItemPage(prevPage => Math.max(prevPage - 1, 1))
  }

  const handleItemSearch = searchText => {
    setItemSearchQuery(searchText)
    setItemPage(1)
  }

  const handleDeleteFolder = async folder => {
    await deleteFolder({ folderId: folder.id, reportGroupId: reportGroup.id })
  }

  const renderItemSearchAndPage = () => {
    return (
      <SearchAndPage
        show={folder.items?.length > 0}
        pageNumber={itemPage}
        totalPagesAvailable={itemsTotalPages}
        placeHolderText='Search Items'
        onSearch={handleItemSearch}
        onPrevPage={handleItemPrevPage}
        onNextPage={handleItemNextPage}
      />
    )
  }

  return (
    <Stack
      justifyContent='space-between'
      direction='column'
      sx={{ width: '100%' }}
    >
      <Box sx={{ px: 2, mt: 1.5 }}>
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='flex-start'
          gap={1}
        >
          <FolderOutlinedIcon />

          <EditableText
            name={folder?.name || ''}
            setName={newName =>
              updateFolder({
                ...folder,
                folderName: newName,
                reportGroupId: reportGroup.id,
                folderId: folder.id,
              })
            }
            sx={{
              variant: 'subtitle1',
              fontWeight: 700,
              color: theme => theme.palette.text.primary,
            }}
          />
          <LinkReportsMenu reportGroup={reportGroup} folder={folder} />

          <Button
            color='error'
            onClick={() => handleDeleteFolder(folder)}
            endIcon={
              deleteLoading ? (
                <Icon>
                  <CircularProgress size={20} />
                </Icon>
              ) : null
            }
          >
            Delete Folder
          </Button>
        </Stack>
        <Divider sx={{ mb: 1 }} />
        {renderItemSearchAndPage()}
        {folder.items?.length === 0 ? (
          <NoResultsText>This group has no linked Items</NoResultsText>
        ) : (
          <LinkedItemChips>
            {paginatedItems.map(item => (
              <LinkedItemChip
                key={item?.id}
                label={`${item.name || item.name}`}
                onDelete={() => handleDelete(item)}
              />
            ))}
          </LinkedItemChips>
        )}
      </Box>
    </Stack>
  )
}

export type LinkedFolderProps = {
  folder: Folder
  reportGroup: ReportGroup
  reportGroupFolders: Folder[]
}
