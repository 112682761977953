import { useEffect, useState } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  ListItemText,
  Collapse,
  List,
  ListItem,
  IconButton,
  alpha,
} from '@mui/material'
import { NavigationLink } from './navigation-link'
import { useHistory } from 'react-router-dom'
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined'
import { FolderNav } from './folder-nav'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import { highlightText } from './highlight-text'
import { ReportGroupType } from './types'

export function ReportGroupNav(props: ReportGroupNavProps) {
  const { reportGroup, searchText } = props
  const { name } = reportGroup

  const history = useHistory()

  const [open, setOpen] = useState(searchText.length > 2)

  useEffect(() => {
    if (searchText?.length > 2) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleDropdownClick = e => {
    e.stopPropagation()
    setOpen(!open)
  }

  const handleClick = e => {
    e.stopPropagation()
    history.push(`/report-groups/${reportGroup.id}`)
  }

  const isSelected = history.location.pathname.includes(
    `/report-groups/${reportGroup.id}`
  )

  return (
    <List disablePadding>
      <ListItem
        onClick={handleClick}
        sx={{
          pr: 1,
          pl: 1,
          '&:hover': {
            backgroundColor: theme => alpha(theme.palette.secondary.main, 0.4),
          },
          backgroundColor: isSelected
            ? theme => alpha(theme.palette.secondary.main, 0.6)
            : 'inherit',
          borderRadius: '24px',
          height: '40px',
        }}
      >
        <SourceOutlinedIcon
          sx={{
            width: '26px',
            height: '26px',
            color: theme =>
              isSelected ? alpha(theme.palette.primary.main, 0.6) : 'inherit',
          }}
        />
        <ListItemText
          primary={highlightText(name, searchText)}
          sx={{ pl: 1, fontSize: '1.2rem', fontWeight: 'bold' }}
        />
        <IconButton
          onClick={handleDropdownClick}
          sx={{
            height: '36px',
            width: '36px ',
          }}
        >
          {open ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ pl: 4 }}>
          {reportGroup?.reports
            ?.filter(report => report?.isKeyReport)
            ?.map((item, index) => (
              <NavigationLink
                key={`navlink-${item.id}-${index}`}
                path={`/reports/${item.id}`}
                dense
                searchText={searchText}
              >
                {item.name}
              </NavigationLink>
            ))}
          {reportGroup?.folders?.map(folder => (
            <FolderNav
              title={folder.name}
              items={folder.items}
              key={folder.name}
              icon={<FolderOutlinedIcon />}
              subFolder
              searchText={searchText}
            />
          ))}
        </List>
      </Collapse>
    </List>
  )
}

type ReportGroupNavProps = {
  reportGroup: ReportGroupType
  searchText: string
}
