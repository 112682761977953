import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder } from '../../models'
import { licenseAtom } from '../../state'
import { getFoldersKey } from '../queries'
import { getNavMenuItemsKey } from '../queries/useGetNavMenuItems'

type CreateFolderContext = {
  previousFolders: Folder[]
}

type RemoveItemFromFavoritesPayload = {
  itemId: string
}

export function useRemoveItemFromFavorites() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: RemoveItemFromFavoritesPayload) =>
      folderApi.removeItemFromFavoritesFolder({ ...payload, licenseId }),

    {
      async onMutate(
        newFolder: RemoveItemFromFavoritesPayload
      ): Promise<CreateFolderContext> {
        await queryClient.cancelQueries([getFoldersKey, licenseId])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          licenseId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, licenseId],
          (old: Folder[]) => {
            if (old) {
              const oldFavorites = old.find(folder => folder.isFavorites)
              const newItems = oldFavorites.items.filter(
                item => item.id !== newFolder.itemId
              )
              const newFavorites = {
                ...oldFavorites,
                items: newItems,
              }
              return [
                ...old.filter(folder => !folder.isFavorites),
                newFavorites,
              ]
            }
          }
        )

        return { previousFolders }
      },

      onSettled: () => {
        queryClient.invalidateQueries([getFoldersKey, licenseId, undefined])
        queryClient.invalidateQueries([getFoldersKey, licenseId])
        queryClient.invalidateQueries([getNavMenuItemsKey, licenseId])
      },
    }
  )
}
