import { useEffect, useRef, useState } from 'react'
import { useRouteMatch, NavLink } from 'react-router-dom'
import {
  Icon,
  ListItemButton,
  ListItemIcon,
  SvgIconTypeMap,
  Tooltip,
  Typography,
} from '@mui/material'
import { Icon as MdiIcon } from '@mdi/react'
import { alpha } from '@mui/material/styles'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { highlightText } from './highlight-text'

export function NavigationLink(props: NavLinkProps) {
  const { path, icon, children: text, iconPath, dense, searchText } = props
  const isTabSelected = !!useRouteMatch({ path, exact: true })

  const textRef = useRef<HTMLDivElement>(null)
  const [isOverflowed, setIsOverflowed] = useState(false)
  useEffect(() => {
    const checkOverflow = () => {
      if (textRef.current) {
        setIsOverflowed(
          textRef.current.scrollWidth > textRef.current.clientWidth
        )
      }
    }

    checkOverflow()
    window.addEventListener('resize', checkOverflow) // Check on resize as well
    return () => window.removeEventListener('resize', checkOverflow)
  }, [])

  return (
    <ListItemButton
      to={path}
      component={NavLink}
      sx={{
        '&:hover': {
          backgroundColor: theme => alpha(theme.palette.secondary.main, 0.4),
        },
        borderRadius: '24px',
        p: 1,
        height: dense ? 40 : 'inherit',
        marginBottom: theme => theme.spacing(1.5),
        color: theme => theme.palette.text.primary,
        backgroundColor: isTabSelected
          ? theme => alpha(theme.palette.secondary.main, 0.2)
          : 'inherit',
      }}
    >
      <ListItemIcon
        sx={{
          marginRight: theme => theme.spacing(1),
          minWidth: 0,
          color: isTabSelected
            ? theme => alpha(theme.palette.primary.main, 0.6)
            : 'inherit',
        }}
      >
        {iconPath ? (
          <MdiIcon path={iconPath} size={1} />
        ) : (
          <Icon component={icon} sx={{ marginRight: 0 }} />
        )}
      </ListItemIcon>

      <Tooltip title={text} disableHoverListener={!isOverflowed}>
        <Typography
          noWrap
          ref={textRef}
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            pr: 1,
          }}
        >
          {highlightText(text, searchText)}
        </Typography>
      </Tooltip>
    </ListItemButton>
  )
}

type NavLinkProps = {
  path: string
  children: string
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  iconPath?: string
  dense?: boolean
  searchText?: string
}
