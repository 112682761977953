import { useState, useRef, useEffect } from 'react'
import {
  TextField,
  Button,
  ClickAwayListener,
  Popover,
  Stack,
} from '@mui/material'

export function AddFolderButton(props: AddFolderButtonProps) {
  const { onConfirm, addButtonLabel, placeHolderText, existingFolders } = props

  const [isOpen, setIsOpen] = useState(false)
  const anchorRef = useRef()

  const [tempFolderName, setTempFolderName] = useState('')
  const [error, setError] = useState(false)

  const openMenu = (): void => {
    setIsOpen(true)
  }

  const closeMenu = (): void => {
    setIsOpen(false)
  }

  useEffect(() => {
    if (existingFolders?.includes(tempFolderName)) {
      setError(true)
    } else {
      setError(false)
    }
  }, [tempFolderName, existingFolders])

  return (
    <>
      <Button ref={anchorRef} color='secondary' onClick={() => openMenu()}>
        {addButtonLabel}
      </Button>
      <Popover
        sx={{ maxHeight: 'calc(80vh - 64px)', borderRadius: '16px' }}
        open={isOpen}
        anchorEl={anchorRef.current}
      >
        <ClickAwayListener onClickAway={() => closeMenu()}>
          <Stack direction='row' alignItems='center'>
            <TextField
              placeholder={placeHolderText}
              value={tempFolderName}
              onChange={e => setTempFolderName(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter' && !error) {
                  onConfirm(tempFolderName)
                  closeMenu()
                }
              }}
              error={existingFolders?.includes(tempFolderName)}
              helperText={error ? 'Folder already exists' : ''}
            />
            <Button
              variant='text'
              disabled={error}
              onClick={() => {
                onConfirm(tempFolderName)
                closeMenu()
              }}
            >
              Add
            </Button>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </>
  )
}

export type AddFolderButtonProps = {
  onConfirm: (folderName: string) => void
  addButtonLabel: string
  placeHolderText?: string
  existingFolders: string[]
}
