import { ApiReport } from '../../models'
import { parseBoolean } from '../../utils/parseBoolean'

export function formatReports(reports): ApiReport[] {
  const formattedReports: ApiReport[] =
    reports?.map(report => {
      let rlsRoles = []
      if (report?.rlsRoles) rlsRoles = JSON.parse(report.rlsRoles)
      return {
        ...report,
        filterPaneEnabled: parseBoolean(report?.filterPaneEnabled || 'false'),
        showPageNavigation: parseBoolean(report?.showPageNavigation || 'false'),
        permissions: report?.permissions
          ? JSON.parse(report?.permissions)
          : null,
        showOnHome: parseBoolean(report?.showOnHome || 'false'),
        rls: {
          roles: rlsRoles,
          username: report?.rlsUsername,
        },
      }
    }) || []

  return formattedReports
}
