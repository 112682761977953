import { ReportGroup } from '../../../../models'
import { v4 as uuidv4 } from 'uuid'
import { ExistingItem, TabLayoutModal } from '../../shared'
import { useDeleteReportGroup } from '../../../../hooks/mutations/useDeleteReportGroup'
import { LinkedReports } from './linked-reports'
import { useState } from 'react'
import { ReportGroupForm } from './report-group-form'
import { ExistingItemSkeleton } from '../../shared/existing-items/existing-item-skeleton'
import { Home } from '@mui/icons-material'
import { LinkFoldersMenu } from './link-folders-menu'

type ExistingReportGroupProps = {
  reportGroup: ReportGroup
}

export const ExistingReportGroup = (props: ExistingReportGroupProps) => {
  const { reportGroup } = props

  const { mutate: deleteReportGroup } = useDeleteReportGroup()
  const [editModalOpen, setEditModalOpen] = useState(false)

  let icon
  if (reportGroup.showOnHome) icon = <Home style={{ fontSize: '16px' }} />

  if (!reportGroup.id) {
    return <ExistingItemSkeleton numberOfItems={1} />
  }

  return (
    <>
      <ExistingItem
        key={`${reportGroup.id}_${uuidv4()}`}
        name={reportGroup.name}
        description={reportGroup.description}
        secondaryLabel={
          reportGroup.permissionTitle
            ? `Permission: ${reportGroup.permissionTitle}`
            : null
        }
        onDelete={() => deleteReportGroup(reportGroup.id)}
        onEdit={() => setEditModalOpen(true)}
        icon={icon}
      >
        <>
          <LinkFoldersMenu reportGroup={reportGroup} />
          <LinkedReports reportGroup={reportGroup} />
        </>
      </ExistingItem>

      <TabLayoutModal
        title='Edit Report Group'
        isOpen={editModalOpen}
        onClose={() => setEditModalOpen(false)}
      >
        <ReportGroupForm
          onClose={() => setEditModalOpen(false)}
          reportGroup={reportGroup}
          editMode={true}
        />
      </TabLayoutModal>
    </>
  )
}
