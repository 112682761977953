import { useMutation, useQueryClient } from 'react-query'
import { AllBookmarks } from '../../models'
import { bookmarkApi } from '../../api-interface'
import { getBookmarksKey } from '..'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'
type DeleteBookmarkContext = {
  previousBookmarks: AllBookmarks
}
export function useDeleteBookmark(reportId) {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(bookmarkApi.deleteBookmark, {
    async onMutate(newBookmarkId: string): Promise<DeleteBookmarkContext> {
      await queryClient.cancelQueries([getBookmarksKey, licenseId, reportId])

      let previousBookmarks: AllBookmarks = queryClient.getQueryData([
        getBookmarksKey,
        licenseId,
        reportId,
      ])
      queryClient.setQueryData(
        [getBookmarksKey, licenseId, reportId],
        (old: AllBookmarks) => {
          if (!old) {
            old = {
              ownedBookmarks: [],
              sharedBookmarks: [],
              licenseBookmarks: [],
            }
          }
          return {
            ownedBookmarks:
              old.ownedBookmarks?.filter(
                bookmark => bookmark.id !== newBookmarkId
              ) || [],
            sharedBookmarks:
              old.sharedBookmarks?.filter(
                bookmark => bookmark.id !== newBookmarkId
              ) || [],
            licenseBookmarks:
              old.licenseBookmarks?.filter(
                bookmark => bookmark.id !== newBookmarkId
              ) || [],
          }
        }
      )

      return { previousBookmarks }
    },
    onError(err, newAppRegId, context: DeleteBookmarkContext) {
      queryClient.setQueryData(
        [getBookmarksKey, licenseId, reportId],
        context.previousBookmarks
      )
    },
    onSettled() {
      queryClient.invalidateQueries([getBookmarksKey, licenseId, reportId])
    },
  })
}
