// import { useRecoilValue } from 'recoil'
import { AllReports, ApiReport } from '../../models'
// import { licenseAtom } from '../../state'
import { reportApi, reportGroupApi } from '../../api-interface'
import { useMutation, useQueryClient } from 'react-query'
import {
  // getUnassignedLicenseReportsKey,
  getReportGroupMembersKey,
  getReportsKey,
} from '../queries'
import { useRecoilValue } from 'recoil'
import { licenseAtom } from '../../state'

type AddReportGroupMemberPayload = {
  reportGroupId: string
  reportIds: string[]
  isKeyReport?: boolean
}

export function useAddReportGroupMembers() {
  const licenseId = useRecoilValue(licenseAtom)
  const queryClient = useQueryClient()

  return useMutation(
    (payload: AddReportGroupMemberPayload) =>
      reportGroupApi.addReportGroupMember({
        reportGroupId: payload.reportGroupId,
        reportIds: payload.reportIds,
        isKeyReport: payload.isKeyReport,
      }),
    {
      async onMutate(
        payload: AddReportGroupMemberPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        let allReports: AllReports = queryClient.getQueryData([
          getReportsKey,
          licenseId,
        ])

        if (!allReports) {
          allReports = await queryClient.fetchQuery(
            [getReportsKey, licenseId],
            () => reportApi.getReports(licenseId)
          )
        }

        const previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const reportsBeingAdded = allReports.licenseReports
              .filter((r: ApiReport) => payload.reportIds.includes(r.id))
              .map((r: ApiReport) => {
                return {
                  ...r,
                  isKeyReport: payload.isKeyReport,
                }
              })

            const reportsToSet = [...old, ...reportsBeingAdded]

            return reportsToSet
          }
        )

        return previousReports || []
      },
    }
  )
}
