import { Skeleton, Stack } from '@mui/material'

export function FolderSkeletons(props: { numFolders: number }) {
  const { numFolders } = props
  const folderSpacing = numFolders > 2 ? 3 : numFolders > 1 ? 2 : 1

  return (
    <Stack
      direction='row'
      gap={6}
      sx={{
        whiteSpace: 'wrap',
        flexWrap: 'wrap',
        overflow: 'auto',
        width: '100%',
        mt: 8,
      }}
    >
      {[...Array(numFolders)].map((_, index) => (
        <Skeleton
          key={index}
          variant='rectangular'
          sx={{
            width: `calc(100% / ${folderSpacing} - ${folderSpacing === 1 ? 0 : 32}px)`,
            minWidth: '340px',
            borderRadius: '8px',
            height: '40px',
          }}
        />
      ))}
    </Stack>
  )
}
