import { Box } from '@mui/material'
import { Folder } from '../../../models'
import { FolderNav } from './folder-nav'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'

export function FolderSection(props: FolderSectionProps) {
  const { folders, searchText } = props

  //Sort folders to put isFavorites first
  folders.sort((a, b) => {
    if (a.isFavorites && !b.isFavorites) return -1
    if (!a.isFavorites && b.isFavorites) return 1
    return 0
  })

  return (
    <Box
      sx={{
        // backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
        borderRadius: '24px',
        py: 0,
      }}
    >
      {folders
        ?.filter(folder => folder.items.length > 0)
        ?.map((folder, index) => (
          <FolderNav
            title={folder.name}
            items={folder.items}
            key={folder.name + index}
            searchText={searchText}
            icon={
              folder?.isFavorites ? (
                <FavoriteBorderIcon />
              ) : (
                <FolderOutlinedIcon />
              )
            }
          />
        ))}
    </Box>
  )
}
type FolderSectionProps = {
  folders: Folder[]
  sectionName: string
  searchText: string
}
