import { DataColumn } from '../../../../models'
import { DataColumnWithFilters } from '../custom-report-table'

export type GenerateReportRDLProps = {
  selectedColumns: DataColumn[]
  filters: DataColumnWithFilters[]
  datasetId: string
  reportName: string
  sortVal?: SortColumn
  filterVal?: FilterColumn
}

//Set a constant that can be accessed using DataColumnTypes.<TypeName>
export const DataColumnTypes = {
  COLUMN: 'column' as const,
  MEASURE: 'measure' as const,
}

//Set a constant that can be accessed using SortDirections.<Direction>
export const SortDirections = {
  ASC: 'ASC' as const,
  DESC: 'DESC' as const,
}

export type TableColumn = {
  tableName: string
  columnName: string
  type: (typeof DataColumnTypes)[keyof typeof DataColumnTypes]
}

export type SortColumn = {
  colId: string
  sort: 'asc' | 'desc'
}

export type FilterColumn = {
  colId: string
  operator: string
  value: any
}
