import { useGetFolders } from '../../../../hooks'
import { useCreateFolder } from '../../../../hooks/mutations/useCreateFolder'
import { ReportGroup } from '../../../../models'
import { AddFolderButton } from './add-folder-button'

type LinkFoldersMenuProps = {
  reportGroup: ReportGroup
}

export const LinkFoldersMenu = (props: LinkFoldersMenuProps) => {
  const { reportGroup } = props

  const { mutateAsync: createFolder } = useCreateFolder()

  const { data: folders, refetch: refetchFolders } = useGetFolders(
    reportGroup?.id
  )

  const handleCreateFolder = async (folderName: string) => {
    await createFolder({
      folderName,
      reportGroupId: reportGroup.id,
    })
    refetchFolders()
  }

  return (
    <AddFolderButton
      onConfirm={(folderName: string) => {
        handleCreateFolder(folderName)
      }}
      addButtonLabel='Add Folder'
      placeHolderText='Folder Name'
      existingFolders={folders?.map((f: any) => f.name) || []}
    />
  )
}
