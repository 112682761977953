import { Stack } from '@mui/material'
import { Folder } from '../../../../models'
import { useEffect, useState } from 'react'
import { SingleFolder } from './single-folder'

type FoldersProps = {
  folders: Folder[]
  userFolders?: Folder[]
}

export function Folders(props: FoldersProps) {
  const { folders, userFolders } = props

  const [foldersWithFavoritesProp, setFoldersWithFavoritesProp] = useState<
    Folder[]
  >([])

  const favoriteFolder =
    folders?.filter(folder => folder.isFavorites)[0] ||
    userFolders?.filter(folder => folder.isFavorites)[0]

  useEffect(() => {
    if (!favoriteFolder) {
      setFoldersWithFavoritesProp(folders)
      return
    }
    setFoldersWithFavoritesProp(
      folders?.map(curFolder => {
        return {
          ...curFolder,
          items: curFolder?.items?.map(item => {
            return {
              ...item,
              isFavorite: favoriteFolder?.items?.some(
                favoriteItem => favoriteItem.id === item.id
              ),
            }
          }),
        }
      })
    )
  }, [favoriteFolder, folders])

  return (
    <Stack
      direction='row'
      gap={6}
      sx={{
        whiteSpace: 'wrap',
        flexWrap: 'wrap',
        overflow: 'auto',
        width: '100%',
        mt: 8,
      }}
    >
      {foldersWithFavoritesProp?.map((folder, index) => (
        <SingleFolder
          key={folder.id + index}
          folder={folder}
          numFolders={foldersWithFavoritesProp?.length}
          userFolders={userFolders ? userFolders : folders}
        />
      ))}
    </Stack>
  )
}
