import { Stack, TextField, Typography } from '@mui/material'
import { useState } from 'react'

type EditableTextProps = {
  name: string
  setName: (name: string) => void
  sx?: any
  maxWidth?: string
}

export const EditableText = (props: EditableTextProps) => {
  const { name, setName, sx } = props

  const [editing, setEditing] = useState(false)
  const [editedName, setEditedName] = useState(name)

  const handleNameClick = () => {
    setEditing(true)
  }

  const handleNameChange = (e: any) => {
    setEditedName(e.target.value)
  }

  const handleNameBlur = () => {
    setEditing(false)
    setName(editedName) // Call the setName prop with the updated name
  }

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      setEditing(false)
      setName(editedName) // Call the setName prop with the updated name
    }
  }

  return (
    <Stack
      height={'20px'}
      direction='row'
      alignItems='center'
      gap={1}
      onBlur={handleNameBlur}
    >
      {editing ? (
        <TextField
          value={editedName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onKeyDown={handleKeyPress}
          autoFocus
          onFocus={e => e.target.select()}
          //remove border
          InputProps={{
            inputProps: {
              style: {
                textAlign: 'left',
              },
            },
          }}
          sx={{
            '& .MuiInputBase-root': {
              border: 'none',
            },
            '& .MuiInputBase-input': {
              fontSize: '0.9rem',
              padding: 0,
              alignSelf: 'left',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none !important',
            },
            mb: '-8px !important',
            height: '30px',
            minHeight: 0,
            padding: 0,
            margin: 0,
            alignSelf: 'flex-end',
            textDecoration: 'underline',
          }}
        />
      ) : (
        <Typography
          variant='subtitle2'
          onClick={handleNameClick}
          sx={{
            '&:hover': {
              cursor: 'pointer',
            },
            ...sx,
          }}
          color={theme => theme.palette.grey[600]}
        >
          {name}
        </Typography>
      )}
    </Stack>
  )
  // return <Options options={options} show={show} isPortrait={isPortrait} />
}
