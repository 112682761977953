import { ApiReport, ReportGroup, ReportGroupToCreate } from '../models'
import { endpoints, getAxios, getUrl } from './util'

export const reportGroupApi = {
  async createReportGroup(params: {
    reportGroup: ReportGroupToCreate
    licenseId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.reportGroups,
      data: { ...params.reportGroup, licenseId: params.licenseId },
    })
  },

  async getReportGroups(licenseId: string): Promise<ReportGroup[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.reportGroups, { licenseId }),
    })

    const reportGroups: ReportGroup[] = response.data.payload.map(
      reportGroup => {
        const { showOnHome, ...rest } = reportGroup
        return {
          showOnHome: showOnHome === 'true',
          ...rest,
        }
      }
    )

    return reportGroups
  },

  async deleteReportGroup(
    reportGroupId: string,
    licenseId: string
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.reportGroups,
      data: { reportGroupId, licenseId },
    })
  },

  async editReportGroup(params: {
    reportGroup: ReportGroupToCreate
    licenseId: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.reportGroups,
      data: params,
    })
  },

  async getReportGroupMembers(reportGroupId: string): Promise<ApiReport[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.reportGroupMembers, { reportGroupId }),
    })

    return response.data?.payload
  },

  async addReportGroupMember(params: {
    reportGroupId: string
    reportIds?: string[]
    isKeyReport?: boolean
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.reportGroupMembers,
      data: params,
    })
  },

  async removeReportGroupMember(
    reportGroupId: string,
    reportId: string,
    onlyRemoveKeyReport?: boolean
  ): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.reportGroupMembers,
      //Fix the api call
      data: { reportGroupId, reportId, onlyRemoveKeyReport },
    })
  },
}
