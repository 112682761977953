import {
  CircularProgress,
  Icon,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Folder, FolderItem } from '../../../../models'
import { useState } from 'react'
import { useRoutes } from '../../../../hooks'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined'
import { useAddItemsToFavorites } from '../../../../hooks/mutations/useAddItemToFavoritesFolder'
import { useHistory } from 'react-router-dom'
import { AddToFolderOptions } from './add-to-folder-options'
import { FolderItemTypeToIcon } from '../util'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { useCreateFolder } from '../../../../hooks/mutations/useCreateFolder'
import { useRemoveItemFromFavorites } from '../../../../hooks/mutations/useRemoveItemFromFavoritesFolder'

type FolderItemProps = {
  item: FolderItem
  provided: any
  onRemove: () => void
  disableEdit?: boolean
  foldersAvailable: Folder[]
}

export function SingleFolderItem(props: FolderItemProps) {
  const { item, provided, onRemove, disableEdit, foldersAvailable } = props

  const routes = useRoutes()
  const history = useHistory()

  const [isHovered, setIsHovered] = useState(false)

  const [isAddingToFolder, setIsAddingToFolder] = useState(false)

  const [isLoadingFavorites, setIsLoadingFavorites] = useState(false)

  const { mutateAsync: addToFavorites } = useAddItemsToFavorites()
  const { mutateAsync: removeFromFavorites } = useRemoveItemFromFavorites()

  const { mutateAsync: updateFolder } = useUpdateFolder()
  const { mutateAsync: createFolder } = useCreateFolder()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleMoreClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleAddToFavorites = async () => {
    setIsLoadingFavorites(true)
    if (item.isFavorite) {
      await removeFromFavorites({ itemId: item.id })
    } else {
      await addToFavorites({ item: item })
    }
    setTimeout(() => {
      setIsLoadingFavorites(false)
      handlePopoverClose()
    }, 1000)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setIsAddingToFolder(false)
  }

  const onItemClicked = () => {
    if (item.accessDenied) return
    //set URL based on item type
    if (item.type === 'bookmark' && item.reportId) {
      history.push(
        routes.reports.report({
          id: item.reportId,
          bookmark: item.id,
        })
      )
      return
    }

    history.push(
      routes.reports.report({
        id: item.id,
      })
    )
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={'space-between'}
      gap={1}
      sx={{
        borderRadius: '8px',
        py: 0.5,
        px: 1,
        '&:hover': {
          backgroundColor: item.accessDenied ? 'default' : 'rgba(0, 0, 0, 0.1)',
        },
        width: '100%',
        cursor: item.accessDenied ? 'default' : 'pointer',
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onItemClicked}
      ref={provided.innerRef}
      {...(!disableEdit ? provided.draggableProps : {})}
    >
      <Stack direction='row' alignItems={'center'} gap={1}>
        <Icon
          {...provided.dragHandleProps}
          sx={{
            cursor: disableEdit ? 'inherit' : 'grab',
          }}
        >
          <FolderItemTypeToIcon type={item.type} />
        </Icon>
        <Typography
          fontSize={'14px'}
          display='inline-block'
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '236px',
          }}
        >
          {item.name}
        </Typography>
        {item.accessDenied && (
          <Typography
            display='inline-block'
            fontSize={'14px'}
            sx={{
              color: theme => theme.palette.error.main,
              fontStyle: 'italic',
            }}
          >
            [Access Denied]
          </Typography>
        )}
      </Stack>
      {isHovered && (
        <IconButton
          onClick={e => {
            e.stopPropagation()
            handleMoreClicked(e)
          }}
          sx={{
            cursor: 'pointer',
            height: '24px',
            width: '24px',
            '&:hover': {
              color: theme => theme.palette.primary.light,
            },
            zIndex: 10,
          }}
        >
          <MoreHorizOutlinedIcon />
        </IconButton>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '8px' } },
        }}
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {isAddingToFolder ? (
          <AddToFolderOptions
            folders={foldersAvailable?.filter(folder => !folder.isFavorites)}
            handleAddToFolder={async (
              folderId: string,
              newFolderName?: string
            ) => {
              if (!item.id) return
              if (newFolderName && folderId === '') {
                await createFolder({
                  folderName: newFolderName,
                  items: [
                    {
                      id: item.id,
                      type: item.type || 'report',
                      name: item.name,
                    },
                  ],
                })
                return
              }

              const folder = foldersAvailable?.find(
                folder => folder.id === folderId
              )

              if (!folder) return
              await updateFolder({
                folderId: folder.id,
                items: [
                  ...folder.items,
                  { id: item.id, type: item.type || 'report', name: item.name },
                ],
              })
            }}
            handleFolderClose={() => {
              setIsAddingToFolder(false)
              handlePopoverClose()
            }}
          />
        ) : (
          <Stack
            direction='column'
            sx={{
              backgroundColor: '#f0f4f8',
              p: 1,
            }}
            gap={1}
          >
            {!disableEdit && (
              <Stack
                direction={'row'}
                alignItems={'center'}
                sx={{
                  borderRadius: '8px',
                  cursor: 'pointer',
                  '&:hover': {
                    color: theme => theme.palette.primary.light,
                  },
                }}
                onClick={e => {
                  e.stopPropagation()
                  onRemove()
                  handlePopoverClose()
                }}
                spacing={1}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{
                    mt: '2px',
                    fontSize: '20px',
                  }}
                />
                <Typography>Remove</Typography>
              </Stack>
            )}
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                borderRadius: '8px',
                cursor: 'pointer',
                '&:hover': {
                  color: theme => theme.palette.primary.light,
                },
              }}
              onClick={e => {
                e.stopPropagation()
                setIsAddingToFolder(true)
              }}
              spacing={1}
            >
              <CreateNewFolderOutlinedIcon
                sx={{
                  mt: '2px',
                  fontSize: '20px',
                }}
              />
              <Typography>Add To Folder</Typography>
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                borderRadius: '8px',
                cursor: 'pointer',
                '&:hover': {
                  color: theme => theme.palette.primary.light,
                },
              }}
              onClick={e => {
                e.stopPropagation()
                handleAddToFavorites()
              }}
              spacing={1}
            >
              {isLoadingFavorites ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  {item?.isFavorite ? (
                    <FavoriteIcon
                      sx={{
                        mt: '2px',
                        fontSize: '20px',
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      sx={{
                        mt: '2px',
                        fontSize: '20px',
                      }}
                    />
                  )}
                </>
              )}
              <Typography>
                {item?.isFavorite ? 'Unfavorite' : 'Favorite'}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Popover>
    </Stack>
  )
}
