import { useMutation } from 'react-query'
import { reportApi } from '../../api-interface'

export const testReportConnectionKey = 'test-report-connection'

export type TestReportConnectionPayload = {
  pbiWorkspaceId: string
  itemId: string
  appRegistrationNodeId: string
  profileId: string
  type: string
}

export function useTestReportConnection() {
  return useMutation((payload: TestReportConnectionPayload) =>
    reportApi.testReportConnection(
      payload.pbiWorkspaceId,
      payload.itemId,
      payload.appRegistrationNodeId,
      payload.profileId,
      payload.type
    )
  )
}
