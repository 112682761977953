import React, { useState } from 'react'
import { Box, Theme } from '@mui/material'
import { makeStyles, createStyles } from '@mui/styles'
import cs from 'classnames'
import { MainAppBar } from './main-app-bar'
import { ResponsiveDrawer } from './responsive-drawer'
import { NavigationLinks } from './navigation-drawer/navigation-links'
import { FullPageLoader } from '../common'
import { ErrorPage } from '../../pages/reports/report-view/error-page'

const pageWidth = 1800
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'auto',
    },
    appBarOffset: {
      [theme.breakpoints.up('md')]: {
        height: '64px',
      },
      [theme.breakpoints.down('md')]: {
        height: '40px',
      },
    },
    main: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      flexGrow: 1,
      // padding: theme.spacing(3),
      overflow: 'hidden',
    },
    fullWidth: {
      width: '100% !important',
      maxWidth: 'unset !important',
      padding: '0 !important',
    },
    mainOpen: {
      // [theme.breakpoints.up('lg')]: {
      //   transition: theme.transitions.create('margin', {
      //     easing: theme.transitions.easing.easeOut,
      //     duration: theme.transitions.duration.enteringScreen,
      //   }),
      //   marginLeft: '273px',
      // },
    },
    hideOnMobile: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    content: {
      maxWidth: `${pageWidth}px`,
      height: 'calc(100% - 64px)',
      margin: '0 auto',
    },
  })
)

export function Layout(props: LayoutProps) {
  const {
    children,
    title,
    showBackButton,
    fullWidth,
    hideLogo,
    hideBackground,
    bookmarkOnClick,
    loading,
    error,
    errorMessage,
    loadingMessage,
    ...rest
  } = props

  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => setIsOpen(o => !o)

  return (
    <Box {...rest} className={classes.root}>
      <MainAppBar
        toggleOpen={toggleOpen}
        showBackButton={showBackButton}
        title={title}
        fullWidth={fullWidth}
      />
      <ResponsiveDrawer toggleOpen={toggleOpen} isOpen={isOpen}>
        <NavigationLinks />
      </ResponsiveDrawer>
      <main
        className={cs(classes.main, {
          [classes.mainOpen]: isOpen,
        })}
      >
        {!hideBackground && <Background />}
        <Box className={classes.appBarOffset} />

        <Box
          className={cs(classes.content, {
            [classes.fullWidth]: fullWidth,
          })}
        >
          {loading ? (
            <FullPageLoader loadingMessage={loadingMessage} />
          ) : error ? (
            <ErrorPage>
              {errorMessage ||
                'Error: User does not have permissions to view this page'}
            </ErrorPage>
          ) : (
            children
          )}
        </Box>
      </main>
    </Box>
  )
}

function Background() {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: -1,
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'theme.palette.background.',
      }}
    />
  )
}

type LayoutProps = React.HtmlHTMLAttributes<HTMLDivElement> & {
  title?: string
  showBackButton?: boolean
  fullWidth?: boolean
  hideLogo?: boolean
  hideBackground?: boolean
  bookmarkOnClick?: () => void
  loading?: boolean
  error?: boolean
  errorMessage?: string
  loadingMessage?: string
}
