import { useQuery } from 'react-query'
import { useRecoilValue } from 'recoil'
import { reportApi } from '../../api-interface'
import { licenseAtom } from '../../state'

export const getNavMenuItemsKey = 'get-nav-menu-items-key'

export function useGetNavMenuItems() {
  const licenseId = useRecoilValue(licenseAtom)
  return useQuery(
    [getNavMenuItemsKey, licenseId],
    () => reportApi.getNavMenuItems(licenseId),
    {
      enabled: !!licenseId,
    }
  )
}
