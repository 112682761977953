import {
  Dialog,
  DialogTitle,
  IconButton,
  Popover,
  Divider,
  debounce,
  Stack,
  Theme,
  Tooltip,
  FormControlLabel,
  Switch,
  Box,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Bookmark, NewBookmark } from '../../../models'
import { getIcons } from './getIcons'
import { createStyles, makeStyles } from '@mui/styles'
import { Form, FormField } from '../../admin/shared'
import {
  useAddBookmarkMember,
  useCreateBookmark,
  useGetBookmarkMember,
  useGetLicense,
  useUpdateBookmark,
} from '../../../hooks'
import { Bookmark as BookmarkIcon } from '@mui/icons-material'
import { SearchAndPage } from '../../admin/shared/existing-items/search-and-page'
import { useRemoveBookmarkMember } from '../../../hooks/mutations/useRemoveBookmarkMember'
import { SVGIcon } from './Icons'
import { useRecoilState, useRecoilValue } from 'recoil'
import { bookmarkAtom } from '../../../state/bookmarkAtom'
import { reportStateAtom } from '../../../state'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      padding: 0,
      margin: '16px 0',
    },
    dialogContent: {
      // display: flex won't allow margins to properly collapse,
      // so we use block instead
      display: 'block',
      padding: theme.spacing(4),
    },
    switchContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(2),
    },
  })
)

export function BookmarkDialog(props: BookmarkDialogProps) {
  const { open, onClose, reportId, bookmark } = props

  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [icon, setIcon] = useState(bookmark?.icon || 'default')

  useEffect(() => {
    setIcon(bookmark?.icon || null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookmark])

  const handleSetIcon = (icon: string) => {
    setIcon(icon)
  }

  const classes = useStyles()

  const togglePopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setIsPopoverOpen(!isPopoverOpen)
  }

  const handleClose = () => {
    onClose()
  }

  const Icon =
    icon !== 'default' && icon ? (
      <SVGIcon url={icon} width='24px' height='24px' />
    ) : (
      <BookmarkIcon />
    )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogContent,
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <Stack
        direction='row'
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <DialogTitle className={classes.title}>
          {bookmark ? 'Edit Bookmark' : 'Create Bookmark'}
        </DialogTitle>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Tooltip title='Change Custom Icon'>
            <IconButton onClick={togglePopover}>{Icon}</IconButton>
          </Tooltip>
          <IconsPopover
            open={isPopoverOpen}
            setIconString={handleSetIcon}
            anchorEl={anchorEl}
            onClose={() => setIsPopoverOpen(false)}
          />
        </Stack>
      </Stack>
      <BookmarkEditForm
        onClose={onClose}
        reportId={reportId}
        icon={icon}
        bookmark={bookmark}
      />
    </Dialog>
  )
}

type BookmarkDialogProps = {
  open: boolean
  onClose: () => void
  reportId: string
  bookmark?: Bookmark // existing bookmark to edit
}

export function BookmarkEditForm(props: BookmarkEditFormProps) {
  // TODO: Fix the save edit/create to not close the dialog until the change is made, or tell the drawer to display skeleton loaders until it is finished
  const { onClose, bookmark, reportId, icon } = props

  const { mutateAsync: createBookmark, isLoading: isSaving } =
    useCreateBookmark(reportId)
  const { mutateAsync: updateBookmark, isLoading: isSavingEdit } =
    useUpdateBookmark(reportId)
  const { mutateAsync: addBookmarkMember, isLoading: isSharing } =
    useAddBookmarkMember()
  const { mutateAsync: removeBookmarkMember, isLoading: isRemoving } =
    useRemoveBookmarkMember()

  const { data: isShared, refetch: refetchIsShared } = useGetBookmarkMember(
    bookmark?.id
  )

  const [bookmarkState, setBookmarkState] = useRecoilState(bookmarkAtom)

  const [share, setShare] = useState<boolean>(isShared || false)

  const toggleShare = () => {
    setShare(o => !o)
  }

  const [name, setName] = useState(bookmark?.name || '')
  const [description, setDescription] = useState(bookmark?.description || '')

  const { data: license } = useGetLicense()
  const reportState = useRecoilValue(reportStateAtom)
  const setBookmarkId = (id: string) => {
    window.history.replaceState({}, '', `/reports/${reportId}/${id}`)
  }

  useEffect(() => {
    setShare(isShared)
  }, [isShared])

  const handleShare = () => {
    toggleShare()
  }

  async function handleSave() {
    try {
      let newBookmark: NewBookmark = {
        id: bookmark?.id,
        name,
        description,
        state: bookmark ? bookmark.state : reportState,
        icon: icon || 'default',
        licenseNodeId: license.id,
        reportId,
      }

      let bookmarkId = bookmark?.id
      if (!bookmark) {
        bookmarkId = (await createBookmark(newBookmark))?.id || null
        if (bookmarkId) setBookmarkId(bookmarkId)
      } else {
        await updateBookmark(newBookmark)
      }
      bookmarkId = bookmarkId || bookmark?.id
      if (!bookmarkId) {
        onClose()
        return
      }

      if (share !== isShared) {
        if (share) {
          await addBookmarkMember({ bookmarkId })
        } else {
          await removeBookmarkMember({ bookmarkId })
        }
        refetchIsShared()
      }
      setBookmarkState({
        ...bookmarkState,
        selectedBookmark: newBookmark,
      })
      onClose()
    } catch (e) {
      onClose()
    }
  }

  return (
    <Form
      onSave={handleSave}
      onCancel={onClose}
      isSaving={isSaving || isSavingEdit || isSharing || isRemoving}
      tertiaryComponent={
        <>
          {license?.isAdmin && (
            <Box
              style={{ display: 'flex', alignItems: 'center', marginTop: 1 }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={share}
                    onChange={handleShare}
                    name='share'
                    color='primary'
                  />
                }
                label={'Shared with License'}
                labelPlacement='start'
              />
            </Box>
          )}
        </>
      }
    >
      <FormField label='Bookmark Title' value={name} onTextChange={setName} />
      <FormField
        label='Description'
        value={description}
        onTextChange={setDescription}
      />
    </Form>
  )
}

type BookmarkEditFormProps = {
  onClose: () => void
  reportId: string
  icon: string
  bookmark?: Bookmark
}

export function IconsPopover(props: IconsPopoverProps) {
  const { open, setIconString, anchorEl, onClose } = props

  const PAGE_SIZE = 40
  const [page, setPage] = useState(1)

  const [searchText, setSearchText] = useState('')
  const icons = getIcons()

  const [filteredIcons, setFilteredIcons] = useState([])
  const [availableIcons, setAvailableIcons] = useState([])
  const totalPagesAvailable = Math.ceil(filteredIcons.length / PAGE_SIZE)

  const debouncedSearch = debounce(() => {
    if (searchText === '') {
      setPage(1)
      const start = (page - 1) * PAGE_SIZE
      const end = start + PAGE_SIZE
      setFilteredIcons(icons)
      setAvailableIcons(icons.slice(start, end))
      return
    }
    const filteredIcons = icons.filter(icon => {
      return icon.name?.toLowerCase().includes(searchText.toLowerCase())
    })
    setFilteredIcons(filteredIcons)
    setPage(1)
    const start = (page - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE

    setAvailableIcons(filteredIcons.slice(start, end))
  }, 300)

  useEffect(() => {
    debouncedSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  useEffect(() => {
    const start = (page - 1) * PAGE_SIZE
    const end = start + PAGE_SIZE
    setAvailableIcons(filteredIcons.slice(start, end))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const handleSearch = (text: string) => {
    setSearchText(text)
  }

  const handleNextPage = () => {
    if (page < totalPagesAvailable) {
      setPage(page + 1)
    }
  }

  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1)
    }
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onBackdropClick={() => {
        setSearchText('')
        onClose()
      }}
      onClose={() => {
        setSearchText('')
        onClose()
      }}
    >
      <Stack direction='column' spacing={1} sx={{ p: 1, width: '400px' }}>
        <SearchAndPage
          show={true}
          totalPagesAvailable={totalPagesAvailable}
          pageNumber={page}
          onSearch={(searchText: string) => handleSearch(searchText)}
          onPrevPage={() => handlePrevPage()}
          onNextPage={() => handleNextPage()}
        />
        <Divider />
        <Stack
          direction='row'
          gap={1}
          sx={{
            width: '400px',
            flexWrap: 'wrap',
          }}
          alignItems={'center'}
          justifyContent={'flex-start'}
        >
          {availableIcons?.map((icon, index) => (
            <IconButton
              key={icon.name + index}
              sx={{
                width: '32px',
                height: '32px',
                margin: '8px',
              }}
              onClick={() => {
                setIconString(icon.url)
                onClose()
              }}
            >
              <img src={icon.url} alt='icon' width='28px' height='28px' />
            </IconButton>
          ))}
        </Stack>
      </Stack>
    </Popover>
  )
}

type IconsPopoverProps = {
  open: boolean
  setIconString: (iconString: string) => void
  anchorEl: null | HTMLElement
  onClose: () => void
}

export default BookmarkDialog
