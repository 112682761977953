import { useMutation, useQueryClient } from 'react-query'
import { AllReports } from '../../models'
import { reportApi } from '../../api-interface'
import { getDashboardKey, getFoldersKey, getReportsKey } from '..'
import { licenseAtom } from '../../state'
import { useRecoilValue } from 'recoil'
import { getNavMenuItemsKey } from '../queries/useGetNavMenuItems'
type DeleteReportContext = {
  previousReports: AllReports
}
export function useDeleteReport() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (reportId: string) => reportApi.deleteReport(reportId, licenseId),
    {
      async onMutate(newReportId: string): Promise<DeleteReportContext> {
        await queryClient.cancelQueries([getReportsKey, licenseId])

        let previousReports: AllReports = queryClient.getQueryData([
          getReportsKey,
          licenseId,
        ])
        queryClient.setQueryData(
          [getReportsKey, licenseId],
          (old: AllReports) => {
            if (!old) {
              old = {
                ownedReports: [],
                sharedReports: [],
                groupedReports: [],
                defaultReports: [],
                licenseReports: [],
              }
            }
            return {
              ownedReports: old.ownedReports?.filter(
                report => report.id !== newReportId
              ),
              sharedReports: old.sharedReports?.filter(
                report => report.id !== newReportId
              ),
              groupedReports: old.groupedReports?.filter(
                report => report.id !== newReportId
              ),
              defaultReports: old.defaultReports?.filter(
                report => report.id !== newReportId
              ),
              licenseReports: old.licenseReports?.filter(
                report => report.id !== newReportId
              ),
            }
          }
        )

        return { previousReports }
      },
      onError(err, newAppRegId, context: DeleteReportContext) {
        queryClient.setQueryData(
          [getReportsKey, licenseId],
          context.previousReports
        )
      },
      onSettled() {
        queryClient.invalidateQueries([getReportsKey, licenseId])
        queryClient.invalidateQueries([getFoldersKey, licenseId])
        queryClient.invalidateQueries([getDashboardKey, licenseId])
        queryClient.invalidateQueries([getNavMenuItemsKey, licenseId])
      },
    }
  )
}
