import { useState, ReactNode } from 'react'
import cs from 'classnames'
import {
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Paper,
  Theme,
  Typography,
  Stack,
  Checkbox,
  Icon,
} from '@mui/material'
import { createStyles, makeStyles } from '@mui/styles'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { OverflowMenu } from './overflowMenu'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      hoverActive: {
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: theme.palette.grey[100],
        },
      },
      card: {
        width: '100%',

        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
      },
      text: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
      },
      cardHeader: {
        width: '100%',
        display: 'flex',
        borderRadius: '4px',
        alignItems: 'center',
        cursor: 'pointer',
        minHeight: '48px',
      },
      name: {
        fontWeight: 700,
        '&:hover, &.Mui-focusVisible': {
          color: theme.palette.secondary.dark,
        },
      },
      nameAndStatus: {
        display: 'flex',
        flexDirection: 'row',
        width: '400px',
        marginRight: theme.spacing(4),
        alignItems: 'center',
      },
      secondaryText: {
        color: 'gray',
        textAlign: 'right',
        textTransform: 'none',
        [theme.breakpoints.down('sm')]: {
          textAlign: 'left',
        },
        marginLeft: theme.spacing(1),
      },
      default: {
        paddingRight: theme.spacing(2),
      },
      more: {
        //marginLeft: theme.spacing(4),//
      },
      statusSuccess: {
        color: theme.palette.success.main,

        marginLeft: theme.spacing(1),
      },
      statusError: {
        color: theme.palette.error.main,

        marginLeft: theme.spacing(1),
      },
      description: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.25),
      },
      rightAlignedIcon: {
        marginLeft: 'auto',
        fontSize: '1rem',
        marginTop: theme.spacing(0.5),
      },
      // isSelected: {
      //   border: '4px solid transparent',
      //   borderLeftColor: theme.palette.primary.main,
      //   borderRadius: '0px',
      //   paddingRight: '4px !important',
      // },
    }),
  { classNamePrefix: 'ExistingItem' }
)

export function ExistingItem(props: ExistingItemProps) {
  const {
    name,
    secondaryLabel,
    children,
    onDelete,
    onEdit,
    onClick,
    editButtonName,
    deleteButtonName,
    loading,
    status,
    description,
    checkboxProps,
    secondaryText,
    nameIcon,
    thirdButtonName,
    onThirdButton,
    icon,
  } = props

  const classes = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [isOverflowOpen, setIsOverflowOpen] = useState(false)

  function handleClick(e: any) {
    if (isOverflowOpen) return

    let isOpening = !isOpen
    if (isOpening) onClick?.()

    setIsOpen(o => !o)
  }

  const isActive = status ? status === 'ACTIVE' : false

  const handleCheck = (e: any) => {
    checkboxProps.onCheck(!checkboxProps.checked)
    e.stopPropagation()
    e.preventDefault()
  }

  return (
    <>
      <Paper
        elevation={0}
        square
        className={cs({ [classes.hoverActive]: !isOpen }, classes.card)}
        sx={{
          position: 'relative',
          '::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 1,
            width: '4px',
            height: '100%',
            backgroundColor: theme =>
              checkboxProps?.checked
                ? theme.palette.primary.main
                : 'transparent',
          },
        }}
      >
        <div className={classes.cardHeader} onClick={handleClick}>
          <div className={classes.text}>
            <Stack direction='row' alignItems='center'>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent={'flex-start'}
                alignContent={'center'}
                gap={1}
              >
                {nameIcon && (
                  <Icon
                    sx={{
                      fontSize: '1rem',
                      color: theme => theme.palette.grey[800],
                    }}
                  >
                    {nameIcon}
                  </Icon>
                )}
                <Typography variant='subtitle1' className={classes.name}>
                  {name}
                </Typography>
              </Stack>
              {description && (
                <Typography
                  fontSize={12}
                  color='textSecondary'
                  className={classes.description}
                >
                  {description}
                </Typography>
              )}
              <Typography
                className={
                  isActive ? classes.statusSuccess : classes.statusError
                }
              >
                {status}
              </Typography>
              {loading && <CircularProgress size={24} />}
            </Stack>
            {icon && <div className={classes.rightAlignedIcon}>{icon}</div>}
            <Typography variant='overline' className={classes.secondaryText}>
              {secondaryText ? secondaryText : secondaryLabel}
            </Typography>
          </div>
          {(onDelete || onEdit) && (
            <OverflowMenu
              isOpen={isOverflowOpen}
              setIsOpen={setIsOverflowOpen}
              onDelete={onDelete}
              onEdit={onEdit}
              thirdButtonName={thirdButtonName}
              onThirdButton={onThirdButton}
              editButtonName={editButtonName}
              deleteButtonName={deleteButtonName}
            />
          )}

          {children && (
            <IconButton size='small' data-testid='expand-collapse'>
              {isOpen ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
          {checkboxProps?.display && (
            <Checkbox
              id={`checkbox-${secondaryLabel}`}
              onClick={e => handleCheck(e)}
              sx={{ mr: (theme: Theme) => theme.spacing(1) }}
              checked={!!checkboxProps?.checked}
            />
          )}
          {/* </Stack> */}
        </div>
        {children && (
          <Collapse mountOnEnter={true} in={isOpen}>
            {children}
          </Collapse>
        )}
        {/* <Box
          sx={{
            width: '4px ',
            position: 'absolute',
            
            display: 'inline-block',
            left: '0',
            height: '60px',
            backgroundColor: theme =>
              checkboxProps?.checked
                ? theme.palette.primary.main
                : 'transparent',
            marginRight: theme => theme.spacing(1),
          }}
        ></Box> */}
      </Paper>
      <Divider />
    </>
  )
}

export type ExistingItemProps = {
  name: string
  description?: string
  secondaryLabel?: string
  onClick?: () => void
  onDelete?: () => void
  onEdit?: () => void
  onThirdButton?: () => void
  children?: ReactNode
  isDefault?: boolean
  onCancel?: () => void
  editButtonName?: string
  deleteButtonName?: string
  thirdButtonName?: string
  status?: string
  loading?: boolean
  nameIcon?: ReactNode
  checkboxProps?: {
    display: boolean
    onCheck: (checked: boolean) => void
    checked: boolean
  }
  secondaryText?: string
  icon?
}
