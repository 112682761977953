import { useEffect, useState } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  ListItemText,
  Collapse,
  List,
  ListItem,
  IconButton,
} from '@mui/material'
import { FolderItem } from '../../../models'
import { getItemPath } from '../../../pages/reports/report-dashboard/folder-utilities'
import { NavigationLink } from './navigation-link'
import { highlightText } from './highlight-text'

export function FolderNav(props: FolderNavProps) {
  const { title, items, icon, subFolder, searchText } = props

  const [open, setOpen] = useState(searchText.length > 2)

  useEffect(() => {
    if (searchText?.length > 2) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <List disablePadding>
      <ListItem
        onClick={handleClick}
        sx={{ pr: 1, pl: subFolder ? 4 : 1, height: '40px', mt: 1 }}
      >
        {icon}
        <ListItemText
          primary={highlightText(title, searchText)}
          sx={{ pl: 1, fontSize: '1.2rem', fontWeight: 'bold' }}
        />
        <IconButton onClick={handleClick}>
          {open ? <ArrowDropDown /> : <ArrowDropUp />}
        </IconButton>
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' sx={{ pl: 4 }}>
          {items?.map((item, index) => (
            <NavigationLink
              key={`navlink-${item.id}-${index}`}
              path={getItemPath(item)}
              dense
              searchText={searchText}
            >
              {item.name}
            </NavigationLink>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

type FolderNavProps = {
  title: string
  items: FolderItem[]
  icon: React.ReactNode
  subFolder?: boolean
  searchText: string
}
