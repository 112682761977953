import { useMutation, useQueryClient } from 'react-query'
import { reportGroupApi } from '../../api-interface'
import { getReportGroupMembersKey } from '../queries'
import { ApiReport } from '../../models'

export const removeReportGroupMemberKey = 'remove-report-group-member'

export type RemoveReportGroupMemberPayload = {
  reportGroupId: string
  reportId: string
  onlyRemoveKeyReport?: boolean
}

export function useRemoveReportGroupMember() {
  const queryClient = useQueryClient()

  return useMutation(
    (payload: RemoveReportGroupMemberPayload) =>
      reportGroupApi.removeReportGroupMember(
        payload.reportGroupId,
        payload.reportId,
        payload.onlyRemoveKeyReport
      ),
    {
      async onMutate(
        payload: RemoveReportGroupMemberPayload
      ): Promise<ApiReport[]> {
        await queryClient.cancelQueries([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const updatedReports = old?.filter(r => r.id !== payload.reportId)
            return updatedReports
          }
        )

        let previousReports: ApiReport[] = queryClient.getQueryData([
          getReportGroupMembersKey,
          payload.reportGroupId,
        ])

        let allPrevReports = previousReports

        if (allPrevReports?.length === 0) {
          previousReports = await queryClient.fetchQuery(
            [getReportGroupMembersKey, payload.reportGroupId],
            () => reportGroupApi.getReportGroupMembers(payload.reportGroupId)
          )
          allPrevReports = previousReports
        }

        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          (old: ApiReport[]) => {
            const updatedReports = old?.filter(
              (oldReport: ApiReport) => oldReport.id !== payload.reportId
            )

            return updatedReports
          }
        )

        return allPrevReports
      },
      onError(
        _err,
        payload: RemoveReportGroupMemberPayload,
        previousReports: ApiReport[]
      ) {
        queryClient.setQueryData(
          [getReportGroupMembersKey, payload.reportGroupId],
          previousReports
        )
      },
    }
  )
}
