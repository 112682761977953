import { LinearProgress, Stack, Typography } from '@mui/material'

type AccessLoadingBarProps = {
  isTestingConnection: boolean
  isAddingProfile: boolean
  isGrantingAccess: boolean
  checkingDataset?: boolean
}

export function AccessLoadingBar(props: AccessLoadingBarProps) {
  const {
    isTestingConnection,
    isAddingProfile,
    isGrantingAccess,
    checkingDataset,
  } = props

  if (
    !isTestingConnection &&
    !isAddingProfile &&
    !isGrantingAccess &&
    !checkingDataset
  ) {
    return null
  }

  return (
    <Stack
      direction='column'
      alignItems='flex-start'
      justifyContent='center'
      sx={{ width: '100%', mt: 1, mb: 4 }}
    >
      <Typography
        sx={{
          fontStyle: 'italic',
          fontSize: '0.9rem',
          width: '40%',
          mb: 0.5,
        }}
      >
        {isAddingProfile || isGrantingAccess
          ? 'Granting access ...'
          : checkingDataset
            ? 'Verifying dataset...'
            : 'Testing connection ...'}
      </Typography>
      <LinearProgress
        color='secondary'
        sx={{ width: '100%', borderRadius: '20px', px: 1, height: '8px' }}
      />
    </Stack>
  )
}
