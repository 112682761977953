import { useEffect, useState } from 'react'
import {
  useAddReportGroupMembers,
  useGetReportGroupMembers,
  useGetReports,
} from '../../../../hooks'
import { useUpdateFolder } from '../../../../hooks/mutations/useUpdateFolder'
import { ReportGroup, ApiReport, Folder, FolderItem } from '../../../../models'
import { LinkItemsMenu } from '../../shared'

type LinkReportsMenuProps = {
  reportGroup: ReportGroup
  folder?: Folder
}

export const LinkReportsMenu = (props: LinkReportsMenuProps) => {
  const { reportGroup, folder } = props
  const { data: reports, isLoading: reportsLoading } = useGetReports()

  const allReports = reports?.licenseReports || []
  const { data: linkedReports, isLoading: loadingLinkedReports } =
    useGetReportGroupMembers(reportGroup.id)
  const { mutate: addReportGroupMembers } = useAddReportGroupMembers()

  const { mutate: updateFolder } = useUpdateFolder()

  const [reportsAvailableToAdd, setReportsAvailableToAdd] = useState<
    ApiReport[]
  >([])

  useEffect(() => {
    const linkedReportsNotLoaded = linkedReports
      ?.map((r: ApiReport) => {
        return !!r?.id
      })
      .some((r: boolean) => r === false)

    let linkedReportIds = []

    linkedReportIds =
      (linkedReports &&
        !linkedReportsNotLoaded &&
        linkedReports.map((r: ApiReport) => r.id)) ||
      []

    if (folder) {
      linkedReportIds = folder.items?.map((i: FolderItem) => i.id) || []
    }

    setReportsAvailableToAdd(
      allReports?.filter((r: ApiReport) => !linkedReportIds.includes(r.id)) ||
        []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedReports, folder, reports])

  const linkReports = async (reportIds: string[]) => {
    if (folder) {
      const reports = reportIds?.map((id: string) => {
        const matchingReport = reportsAvailableToAdd.find(
          (r: ApiReport) => r.id === id
        )

        if (!matchingReport) {
          return null
        }

        return {
          id,
          type: matchingReport?.type || 'report',
          name: matchingReport?.name,
        }
      })

      const reportItemsToAdd: FolderItem[] =
        reports.map((r: any) => {
          return {
            id: r.id,
            type: r.type,
            name: r.name,
          }
        }) || []

      const existingItems = folder?.items || []

      await updateFolder({
        folderId: folder.id,
        reportGroupId: reportGroup.id,
        items: [...existingItems, ...reportItemsToAdd],
      })
    }

    await addReportGroupMembers({
      reportGroupId: reportGroup.id,
      reportIds: reportIds,
      isKeyReport: !folder,
    })
  }

  return (
    <>
      <LinkItemsMenu
        onConfirm={linkReports}
        addButtonLabel='Add Reports'
        placeHolderText='Search Reports'
        loading={reportsLoading || loadingLinkedReports}
        availableItems={
          (reportsAvailableToAdd &&
            reportsAvailableToAdd.map((r: ApiReport) => {
              return {
                id: r.id,
                name: r.name || r.name,
                owner: r.owner,
                pbiReportId: r.pbiReportId,
                pbiWorkspaceId: r.pbiWorkspaceId,
              }
            })) ||
          []
        }
      />
    </>
  )
}
