import { useCallback, useEffect, useState } from 'react'
import {
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { TabLayout } from '../../shared'
import { ExistingReport } from './existing-report'
import { useGetReports } from '../../../../hooks'
import { filterIt, compareString } from '../../../../utils'
//import {onlyUnique} from '../../../reports/util'
import { SortControls, SortDirection } from '../../shared/sort-controls'
import { NoResultsText } from '../../../../components'
import { ExistingItemSkeleton } from '../../shared/existing-items/existing-item-skeleton'
import { AddReportForm } from './add-report-form/add-report-form'
import { ApiReport } from '../../../../models'
import { FilterBox } from '../users/filter-users-box'

export function ReportsConfig() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { refetch: getReports } = useGetReports()

  function handleSave() {
    setIsModalOpen(false)
    getReports()
  }

  return (
    <>
      <TabLayout
        tabTitle='Items'
        primaryButton={{
          buttonText: 'Create new',
          onClick: () => setIsModalOpen(true),
        }}
      >
        <ReportDisplay />
      </TabLayout>
      {isModalOpen && (
        <AddReportForm
          onClose={() => setIsModalOpen(false)}
          onSave={() => {
            handleSave()
          }}
          open={isModalOpen}
        />
      )}
    </>
  )
}
const pagingThresholds = [25, 50, 100]

function ReportDisplay() {
  const { data: reports, isLoading: reportsLoading } = useGetReports()

  const allReports = reports?.licenseReports || []

  const [skeletonNumber, setSkeletonNumber] = useState(pagingThresholds[0])

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(pagingThresholds[0])

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  // TODO: Consider making a component for the search box...
  const SearchBox = useCallback(() => {
    return (
      <TextField
        id='standard'
        variant='outlined'
        fullWidth
        label='Search'
        onChange={handleSearchTermChange}
        sx={{ width: '200px' }}
        size='small'
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports])

  const [sortedReports, setSortedReports] = useState(allReports)
  const [sortValue, setSortValue] = useState<string>('displayName')
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Ascending
  )
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<string[]>([])

  const paginatedReports = sortedReports.slice(startIndex, endIndex)

  function handleSortAndFilter() {
    const isAsc: boolean = sortDirection === SortDirection.Ascending
    if (!allReports) {
      return
    }
    const filteredItems = filterItems(allReports, filter) //Filter
    const searchedItems = filterIt(filteredItems.slice(), search) // Search

    setSortedReports(
      [...searchedItems].sort((a, b) => {
        return isAsc
          ? compareString(a[sortValue], b[sortValue])
          : compareString(b[sortValue], a[sortValue])
      })
    )
  }

  const handleChangeItemsPerPage = (event: any) => {
    setItemsPerPage(event.target.value as number)
    setCurrentPage(1) // Reset to the first page when changing items per page
  }

  useEffect(() => {
    if (reports?.licenseReports && reports?.licenseReports.length > 0)
      handleSortAndFilter()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, sortValue, sortDirection, search, filter])

  const handleSearchTermChange = e => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem('reports')
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (_e: any, value: number) => {
    setCurrentPage(value)
  }

  useEffect(() => {
    if (reportsLoading || !allReports) return
    localStorage.setItem('reports', JSON.stringify(allReports.length))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsLoading])

  if (!reportsLoading && !allReports.length)
    return <NoResultsText>You haven't created any reports!</NoResultsText>

  return (
    <Stack sx={{ height: '100%' }} direction='column'>
      {/* Search and sort box */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={'space-between'}
        sx={{
          width: '100%',
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          sx={{ mb: 1 }}
          gap={1}
          justifyContent={'flex-start'}
        >
          <SearchBox />
          <SortControls
            sortValue={sortValue}
            onSortValueChange={sortBy => {
              setSortValue(sortBy)
            }}
            sortDirection={sortDirection}
            onSortDirectionChange={direction => {
              setSortDirection(direction)
            }}
            sortColumns={[{ label: 'Name', value: 'displayName' }]}
          />
        </Stack>
        <FilterBox
          setFilters={setFilter}
          filters={filter}
          options={[
            { label: 'Custom Paginated', value: 'custom-paginated' },
            { label: 'Paginated', value: 'paginated-report' },
            { label: 'Report', value: 'report' },
            { label: 'Dataset', value: 'dataset' },
          ]}
        />
      </Stack>

      {/* Reports */}
      {reportsLoading && (
        <ExistingItemSkeleton numberOfItems={skeletonNumber} />
      )}
      {paginatedReports && paginatedReports.length > 0 && (
        <>
          {paginatedReports.map(report => (
            <ExistingReport
              key={report.id}
              report={report}
              isDisabled={false}
            />
          ))}
        </>
      )}
      {allReports && allReports.length > 0 && (
        <Stack
          direction='row'
          justifyContent='flex-end'
          marginTop={2}
          alignItems={'center'}
        >
          <Stack
            direction='row'
            justifyContent='center'
            marginTop={2}
            alignItems={'center'}
          >
            <Select
              value={itemsPerPage}
              onChange={handleChangeItemsPerPage}
              sx={{
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                mt: -2,
              }}
              inputProps={{
                padding: 1,
              }}
              size='small'
            >
              {pagingThresholds.map((threshold: number) => (
                <MenuItem key={threshold} value={threshold}>
                  {threshold}
                </MenuItem>
              ))}
            </Select>
          </Stack>
          <Typography
            fontSize={12}
            sx={{
              mr: 1,
            }}
          >
            Per page
          </Typography>
          <Pagination
            count={Math.ceil(sortedReports.length / itemsPerPage)}
            shape='rounded'
            page={currentPage}
            onChange={handleChange}
          />
        </Stack>
      )}
    </Stack>
  )

  function filterItems(items: ApiReport[], filter: string[]) {
    if (filter.length === 0) return items
    return items.filter(item => filter.includes(item.type))
  }
}
