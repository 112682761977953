import { useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { User } from '../../../../models'
import CloseIcon from '@mui/icons-material/Close'
import FilterListIcon from '@mui/icons-material/FilterList'
import { useGetAppSettings } from '../../../../hooks'

type FilterBoxProps = {
  setFilters: (filters: string[]) => void
  filters: string[]
  options?: { label: string; value: string }[]
}

export function FilterBox(props: FilterBoxProps) {
  const { setFilters, filters, options } = props

  const { data: appSettings } = useGetAppSettings()

  const includeOwner = appSettings?.payPalEnabled

  const filterOptions = options
    ? options
    : [
        { label: 'User', value: 'viewer' },
        { label: 'Admin', value: 'admin' },
        includeOwner && { label: 'Owner', value: 'owner' },
      ]

  const [openSelectBox, setOpenSelectBox] = useState(false)

  const toggleFilterItem = (filterValue: string) => {
    if (filters.includes(filterValue)) {
      setFilters(filters.filter(filter => filter !== filterValue))
    } else {
      setFilters([...filters, filterValue])
    }
  }

  return (
    <Stack direction='row'>
      {/* <NumberOfTools /> */}
      <FilterDisplay />
      <Button
        onClick={() => {
          setOpenSelectBox(!openSelectBox)
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0px !important',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
        disableRipple
      >
        <FilterListIcon
          sx={{
            transform: openSelectBox ? 'rotate(180deg)' : 'rotate(0deg)',
            transformOrigin: 'center center 0px !important',
            transition: '150ms ease',
            color: filters.length > 0 ? 'secondary.main' : 'black',
          }}
        />
      </Button>
      <FormControl variant='outlined' size='small'>
        <Select
          multiple
          open={openSelectBox}
          value={['']}
          style={{
            width: '0px',
            height: '40px',
            visibility: 'hidden',
          }}
          labelId='filter-label'
          onOpen={() => setOpenSelectBox(true)}
          onClose={() => setOpenSelectBox(false)}
        >
          {filterOptions.map(
            filter =>
              filter?.value && (
                <MenuItem key={filter.value} value={filter.value}>
                  <FilterCheckbox
                    filters={filters}
                    filter={filter}
                    toggleFilterItem={toggleFilterItem}
                  />
                </MenuItem>
              )
          )}
          <MenuItem>
            <Button
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                color: 'secondary.main',
              }}
              variant='text'
              size='small'
              onClick={e => {
                e.stopPropagation()
                setOpenSelectBox(false)
                setFilters([])
              }}
              disableRipple
            >
              Clear filters
            </Button>
          </MenuItem>
        </Select>
      </FormControl>
    </Stack>
  )

  function FilterDisplay() {
    return (
      <Stack direction='row' alignItems='center'>
        {filters.map((filter, index) => {
          return (
            <Box
              key={filter + index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '6px',
                backgroundColor: 'secondary.light',
                borderRadius: '6px',
                color: 'white',
                transparent: '90',
                height: '30px',
                marginLeft: '8px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  color: 'white',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '4px',
                }}
              >
                {filterOptions.find(option => option.value === filter)?.label}
              </Typography>
              <IconButton
                sx={{
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                  padding: '0px',
                  margin: '0px',
                }}
                onClick={() => toggleFilterItem(filter)}
              >
                <CloseIcon
                  sx={{
                    color: 'white',
                    height: '20px',
                  }}
                />
              </IconButton>
            </Box>
          )
        })}
      </Stack>
    )
  }

  type FilterCheckboxProps = {
    filters: string[]
    filter: { label: string; value: string }
    toggleFilterItem: (filterValue: string) => void
  }

  function FilterCheckbox(props: FilterCheckboxProps) {
    const { filters, filter, toggleFilterItem } = props

    return (
      <Box
        sx={{ width: '100%', height: '100%' }}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
          toggleFilterItem(filter.value)
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                '&.Mui-checked': {
                  color: 'secondary.main',
                },
                color: 'secondary.main',
              }}
              checked={filters.includes(filter.value)}
            />
          }
          label={filter.label}
        />
      </Box>
    )
  }
}

export function filterUsers(users: User[], filter: string[]) {
  if (filter.length === 0) return users
  return users.filter(user => filter.includes(user.userRole))
}
