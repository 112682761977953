import { useState } from 'react'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Collapse, IconButton, Stack, Typography } from '@mui/material'

export function NavigationSection(props: NavigationSectionProps) {
  const { title, collapsible, children } = props
  const [open, setOpen] = useState(true)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={{
          marginLeft: theme => theme.spacing(2),
          marginBottom: theme => theme.spacing(1),
        }}
      >
        <Typography variant='overline'>{title}</Typography>
        {collapsible && (
          <IconButton onClick={handleClick}>
            {open ? <ArrowDropDown /> : <ArrowDropUp />}
          </IconButton>
        )}
      </Stack>
      <Collapse in={open} sx={{ overflow: 'hidden' }} timeout={350}>
        {children}
      </Collapse>
    </>
  )
}

type NavigationSectionProps = {
  title: string
  collapsible?: boolean
  children: React.ReactNode
}
